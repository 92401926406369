<template>
  <div>
    <div class="drawer-nav__type">
      <h4>{{ $t('generic.factoriesTitle') }}</h4>
    </div>
    <factories-list />
    <div class="drawer-nav__type">
      <h4>{{ $t('factories.purchaseManager') }}</h4>
    </div>
    <div class="drawer-nav__contact">
      <div
        v-for="contact in contacts[$root.siteLanguageKey]"
        :key="contact.name"
        class="d-flex mb-2"
      >
        <div v-if="contact.avatarUrl">
          <img :src="`/images/${contact.avatarUrl}`" :alt="contact.name" class="avatar" />
        </div>
        <div v-else class="avatar" />
        <div class="d-flex flex-column ml-3">
          <div class="worker">
            {{ contact.name }}
          </div>
          <p v-if="contact.position" class="position">
            {{ contact.position }}
          </p>
          <div>
            <a :href="`tel:${contact.phone.replace(/ /g, '')}`">
              <span>{{ contact.phone }}</span>
            </a>
          </div>
          <div>
            <a :href="`mailto:${contact.email}`">
              <span>{{ contact.email }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="drawer-nav__text">
      <p class="font-sz-75">
        {{ $t('factories.disclaimer') }}
        <a :href="`mailto:${$root.email[$root.siteLanguageKey]}`">
          <span>{{ $root.email[$root.siteLanguageKey] }}</span>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import FactoriesList from '../../FactoriesList.vue';

export default {
  name: 'Factories',
  components: { FactoriesList },
  data() {
    return {
      contacts: {
        lt: [
          {
            name: 'Inga Mockuvienė',
            position: 'Logistikos ir LT pirkimų skyriaus vadovė',
            phone: '+370 675 60983',
            email: 'i.mockuviene@jtimber.lt',
          },
        ],
        lv: [
          {
            name: 'Valters Apfelbaums',
            phone: '+371 29 529 115',
            email: 'v.apfelbaums@jtimber.lv',
            avatarUrl: 'valters.jpg',
          },
        ],
      },
    };
  },
};
</script>

<style scoped></style>
