<template>
  <div class="district-p-l">
    <div class="drawer-nav__header">
      <div class="d-flex align-items-center">
        <img src="/images/svg/mail.svg" alt="mail" />
        <h3>{{ $t('generic.contacts') }}</h3>
      </div>
    </div>
    <div class="drawer-nav__type">
      <h4>{{ $t('generic.factoriesTitle') }}</h4>
    </div>
    <factories-list />
    <div class="drawer-nav__type">
      <h4>{{ $t('contacts.contactPersons') }}</h4>
    </div>
    <div class="drawer-nav__contact">
      <div
        v-for="contact in contacts[$root.siteLanguageKey]"
        :key="contact.name"
        class="d-flex mb-2"
      >
        <div v-if="contact.avatarUrl">
          <img :src="`/images/${contact.avatarUrl}`" :alt="contact.name" class="avatar" />
        </div>
        <div v-else class="avatar" />
        <div class="d-flex flex-column ml-3 flex">
          <div class="worker">
            {{ contact.name }}
          </div>
          <p v-if="contact.position" class="position">
            {{ contact.position }}
          </p>
          <div>
            <a :href="`tel:${contact.phone.replace(/ /g, '')}`">
              <span>{{ contact.phone }}</span>
            </a>
          </div>
          <div>
            <a :href="`mailto:${contact.email}`">
              <span>{{ contact.email }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="drawer-nav__type">
      <h4>{{ $t('contacts.requisites') }}</h4>
    </div>
    <div class="drawer-nav__text">
      <div class="mb-3">
        <strong>{{ $t('contacts.title') }}</strong>
      </div>
      <div class="mb-3">
        <strong>{{ $t('labels.address') }}:</strong>
        <p>{{ $t('generic.juodeliaiAddress.street') }},</p>
        <p>{{ $t('generic.juodeliaiAddress.city') }},</p>
        <p>{{ $t('generic.juodeliaiAddress.lithuania') }}</p>
      </div>
      <div class="mb-3">
        <p>
          {{ $t('labels.emailShort') }}:
          <a :href="`mailto:${email}`">
            <span>{{ email }}</span>
          </a>
        </p>
        <p>
          {{ $t('labels.website') }}:
          <a :href="`https://${siteUrl}`">
            <span>{{ siteUrl }}</span>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FactoriesList from '../../FactoriesList.vue';

export default {
  name: 'Contacts',
  components: { FactoriesList },
  data() {
    return {
      emails: {
        lt: 'info@juodeliaiperka.lt',
        lv: 'info@perkukoksni.lv',
      },
      url: {
        lt: 'www.juodeliaiperka.lt',
        lv: 'www.perkukoksni.lv',
      },
      contacts: {
        lt: [
          {
            name: 'Inga Mockuvienė',
            position: 'Logistikos ir LT pirkimų skyriaus vadovė',
            phone: '+370 675 60983',
            email: 'i.mockuviene@jtimber.lt',
          },
          {
            name: 'Kasparas Gervylius',
            phone: '+370 648 06590',
            position: 'Privačios žaliavos vadybininkas',
            email: 'k.gervylius@jtimber.lt',
          },
          {
            name: 'Aira Maksimavičienė',
            position: 'Logistikos vadybininkė',
            phone: '+370 687 99677',
            email: 'a.maksimaviciene@jtimber.lt',
          },
        ],
        lv: [
          {
            name: 'Valters Apfelbaums',
            phone: '+371 29 529 115',
            email: 'v.apfelbaums@jtimber.lv',
            avatarUrl: 'valters.jpg',
          },
          {
            name: 'Kasparas Gervylius',
            phone: '+370 648 06590',
            email: 'k.gervylius@jtimber.lt',
          },
        ],
      },
    };
  },
  computed: {
    siteUrl() {
      return this.url[this.$root.siteLanguageKey];
    },
    email() {
      return this.emails[this.$root.siteLanguageKey];
    },
  },
};
</script>

<style scoped></style>
